.ant-layout-sider {
  background: #29313a !important;
  min-height: 884px !important;
}

/* menu */
#siderbar,
.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background: #29313a !important;
  border: none !important;
  color: #bfbfbf !important;
  font-weight: bold !important;
}

/* sub menu */
.ant-menu-dark .ant-menu-item-selected > a {
  color: #ffffff !important;
  font-weight: normal;
}

.ant-menu-dark .ant-menu-item,
.ant-menu-dark .ant-menu-item-group-title,
.ant-menu-dark .ant-menu-item > a {
  color: #bfbfbf;
  font-weight: normal;
}

.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
  background: #29313a !important;
  border: none !important;
  color: #bfbfbf !important;
  font-weight: bold !important;
}

.ant-layout-header {
  padding: 0px;
}

.ant-table {
  font-size: 14px !important;
}

.ant-layout,
.ant-layout-has-sider,
.ant-typography,
.ant-drawer-content,
.ant-modal-content {
  font-family: "Kanit", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-style: normal;
}

.ant-table.ant-table-middle .ant-table-title,
.ant-table.ant-table-middle .ant-table-footer,
.ant-table.ant-table-middle .ant-table-thead > tr > th,
.ant-table.ant-table-middle .ant-table-tbody > tr > td,
.ant-table.ant-table-middle tfoot > tr > th,
.ant-table.ant-table-middle tfoot > tr > td {
  padding: 8px 8px;
}

.align-end {
  text-align: right;
}

.align-start {
  text-align: left;
}

.align-center {
  text-align: center;
}

.vertical-top {
  vertical-align: top;
}

.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > thead
  > tr
  > th {
  border: 1px solid #bfbfbf !important;
  color: #fafbfc;
}

#sub-table
  > .ant-table-container
  > .ant-table-content
  > table
  > thead
  > tr
  > th {
  color: #ffffff !important;
  background-color: #707d93 !important;
  border: 1px solid #ddd;
}

.ant-table-thead > tr > th {
  background: #29313a !important;
  color: #fafbfc;
}

.ant-row {
  display: flex !important;
}

.space-between-middle {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}

.gutter-8 {
  margin: 0px 0px 8px;
}

.ant-cascader-menu {
  height: auto;
}

.steps-content {
  margin-top: 60px;
  margin-bottom: 60px;
}

table.marker {
  border-collapse: collapse;
  width: 100%;
}

table.marker td,
table.marker th {
  border: 1px solid #ddd;
  padding: 8px;
}

table.marker tr:nth-child(even) {
  background-color: #f2f2f2;
}

table.marker tr:hover {
  background-color: #ddd;
}

table.marker th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #528bc7;
  color: white;
}

.ant-radio-wrapper .ant-radio-wrapper-checked {
  margin-bottom: 10px;
}

.ant-form-item-explain.ant-form-item-explain-error {
  font-size: 11px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

#sub1 .ant-menu-submenu-title {
  color: #333333 !important;
}

.hidden {
  display: none;
}

.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  min-height: 30px;
  cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 4px 11px;
}

td.ant-picker-cell.ant-picker-cell-week {
  color: #1890ff !important;
}

li.ant-picker-ok > button.ant-btn.ant-btn-primary.ant-btn-sm {
  height: 32px !important;
  padding: 4px 15px !important;
  font-size: 14px !important;
}

li.ant-menu-item {
  font-family: "Kanit" !important;
}

.table-mold {
  width: 100%;
  border-collapse: collapse;
  line-height: 1.5715;
}

.table-mold > tbody > tr > td {
  border: 1px solid #dcdcdc;
  font-weight: 400;
  padding: 8px 8px;
  font-size: 14px;
}

.table-mold > thead > tr > th {
  background: #cde6fc;
  border: 1px solid #afd4f9;
  font-weight: 500;
  padding: 8px 8px;
  font-size: 14px;
  text-align: center;
  color: rgba(0, 0, 0, 0.85);
  position: sticky;
  top: 0;
}

.inline {
  display: inline !important;
}

.ant-select-item-option-content {
  flex: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-line !important;
}

.ant-badge-status-dot {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 10px !important;
  height: 10px !important;
  vertical-align: middle;
  border-radius: 50%;
}

th.column-text-center,
td.column-text-center {
  text-align: center !important;
}

.table-mold thead {
  display: table;
  /* to take the same width as tr */
  width: calc(100% - 17px);
  /* - 17px because of the scrollbar width */
}

.table-mold tbody {
  display: block;
  /* to enable vertical scrolling */
  max-height: 200px;
  /* e.g. */
  overflow-y: scroll;
  /* keeps the scrollbar even if it doesn't need it; display purpose */
  /* width: calc(100%);   */
}

.ant-select-selection-item {
  height: 100% !important;
}

.ant-select-multiple .ant-select-selection-item-content {
  white-space: normal !important;
}

.ant-carousel .slick-prev::before {
  content: "";
}

.ant-carousel .slick-next::before {
  content: "";
}

.ant-carousel .slick-prev {
  left: -10px;
}

.ant-carousel .slick-next {
  right: -14px;
}

.ant-carousel .slick-list .slick-slide.slick-active {
  text-align: center;
}

.ranking-row {
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
}

.ranking-container {
  position: relative;
  text-align: center;
  padding: 5px;
}

.ranking-container-img {
  width: 40px;
}

.ranking-container-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: bold;
  font-size: 14px;
}

.ranking-container-small {
  position: relative;
  text-align: center;
  padding: 2px;
}

.ranking-container-img-small {
  width: 25px;
}

.ranking-container-text-small {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -40%);
  font-weight: bold;
  font-size: 10px;
}

.rankin-active-row {
  background: #dbfaff;
}

.option-ranking-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.option-ranking-badge {
  display: flex;
}

.add_border_radius > .ant-select-selector {
  border-radius: 8px !important;
}
.sdfsfsdflsmdlfmklsdmfkmdsklfmlsdmflmasldkfmdmmmmmmmm
  > .ant-select-selector
  > .ant-col
  > .ant-form-item-control-input
  > .ant-form-item-control-input-content
  > .ant-select
  > .ant-select-selector {
  border-radius: 8px !important;
}

.containerStyle {
  /* display: flex; */
  /* height: "10px"; */
  cursor: pointer;
  transition: "all 0.9s ease-in-out";
  /* margin-bottom: 1px; */
}

.containerStyle:hover {
  transform: scale(1.01);
}

.scrollable-container-calendar {
  overflow-x: auto;
  white-space: nowrap;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
}

.scrollable-container-calendar::-webkit-scrollbar {
  display: none; /* Chrome, Safari */
}

.borderRadiusSelectOptionVacation > .ant-select-selector {
  border-radius: 10px !important;
}

.borderRadiusSelectOptionVacation > .ant-select-selector::-webkit-scrollbar {
  display: none; /* Chrome, Safari */
}

.borderRadiusTextArea > .ant-input {
  border-radius: 10px;
}

.borderRadiusPopoverCalendar > .ant-popover-content > .ant-popover-inner {
  border-radius: 20px;
  width: 250px;
}

.vacation-calendar-table {
  background: rgb(255 136 136 / 60%);
}
